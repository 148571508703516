import { AppBar, Box, Dialog, IconButton, List, ListItem, ListItemSecondaryAction, Toolbar, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { Scrollbar } from 'react-scrollbars-custom'
import CloseIcon from '@material-ui/icons/Close';
import PRPTemplate from '../../PRPTemplate/PRPTemplate';
import { getSecondaryColor } from '../../Helper/CommonThings';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { getCookie } from '../../../utils/CookieHelper';
import Service from '../../../config/networkutils';
import { withSnackbar } from 'notistack';

const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"))


class ProjectList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOppurtunity: {},
            open: false
        }
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }


    setData(data, event) {
        if (event.detail === 1) {
            this.props.getGraph(data)
            localStorage.setItem('lastSelectedProject', JSON.stringify(data))
        }
        if (event.detail === 2) {
            let obj = {
                id: data.id,
                contractor_start_date: data.contractor_start_date,
                contractor_end_date: data.contractor_end_date,
                name: data.name,
                opportunity_id: data.opportunity_id
            }
            this.setState({ selectedOppurtunity: obj }, () => { this.setState({ open: true }) })
        }
    }

    handleClose = () => { 
        this.setState({ open: false })
        localStorage.removeItem('searchKey')
        localStorage.removeItem('openedRows')
     }

    postFavourite(rowData) {
        this.setState({ isLoading: true })
        if ('crm_user_pref_id' in rowData) {
            let obj = {
                "is_favourite": !rowData.is_favourite,
            }
            Service.patch(`/api/crm/user/pref/${rowData.crm_user_pref_id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: obj,
            })
                .then(res => {
                    this.props.getData()
                    this.setState({ isLoading: false })
                    this.props.disableShowAll()
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    console.log(error)
                    // this.props.enqueueSnackbar('Something went wrong!', {
                    //     variant: 'error'
                    // });
                })
        } else {
            let obj = {
                "crm": rowData.id,
                "ptd_employee": user.id,
                "is_favourite": !rowData.is_favourite,
                "created_by": user.id
            }
            Service.post(`/api/crm/user/pref/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: obj,
            })
                .then(res => {
                    this.props.getData()
                    this.setState({ isLoading: false })
                    this.props.disableShowAll()
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    console.log(error)
                    // this.props.enqueueSnackbar('Something went wrong!', {
                    //     variant: 'error'
                    // });
                })

        }
    }


    render() {
        return (
            <div>
                <div>
                    <Scrollbar style={{ width: this.screenSizeCheck() ? "350px" : "500px", height: window.innerHeight - 175 }}>
                        <List>
                            {this.props.data.map((item, index) => {
                                return (
                                    <ListItem key={index} button onClick={(event) => this.setData(item, event)}>
                                        {item.name}
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => this.postFavourite(item)}>
                                                {item.is_favourite ? <StarIcon style={{ color: getSecondaryColor() }} /> : <StarBorderIcon style={{ color: getSecondaryColor() }} />}
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                            }
                        </List>
                    </Scrollbar>
                </div>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.state.selectedOppurtunity.name}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        <PRPTemplate project={this.state.selectedOppurtunity}
                            // closeModal={this.handleClose} 
                            addPRPAccess={true}
                            editPRPAccess={true}
                            uploadPRPAccess={true}
                            deletePRPAccess={true} />
                    </Box>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(ProjectList)
